import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalContent, ModalFooter, Input, Button, Select, ModalHeader, SelectItem } from "@nextui-org/react";
import axios from "axios";


export default function WaitlistModal({ visible, setVisible }) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [foundUs, setFoundUs] = useState(new Set([]));
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const [isLinkedin, setIsLinkedin] = useState(false);

    useEffect(() => {
        if(foundUs){
            if(foundUs.has("linkedin")){
                setIsLinkedin(true);
            } else {
                setIsLinkedin(false);
            }
        }
    }, [foundUs]);

    const closeHandler = () => {
        setVisible(false);
    }

    const onSubmit = async () => {
        if(isLinkedin){
            window.open("https://alpha.rubbrband.com", "_blank");
            return;
        }
        if(!firstName || !lastName || !email || !foundUs) {
            setError(true);
            return;
        }
        setLoading(true);
        let endpoint = "https://superman.rubbrband.com/add_waitlist_customer";

        let val = Array.from(foundUs);
        
        if(val.length < 1 ) {
            setError(true);
            return;
        }


        let body = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            found_us: val[0]
        }

        await axios.post(endpoint, body);
        setSubmitted(true);
        setLoading(false);

        setTimeout(() => {
            setVisible(false);
        }, 2000);
    }

    let options = [
        { label: "Linkedin", value: "linkedin" },
        { label: "Google", value: "google" },
        { label: "A Friend", value: "friend" },
        { label: "Hackernews", value: "hackernews" },
        { label: "Reddit", value: "reddit" },
        { label: "Twitter", value: "twitter" },
        { label: "Other", value: "other" }
    ]

    return (
        <Modal
            closeButton
            isOpen={visible}
            onClose={() => {
                closeHandler();
            }}
            size="md"
            backdrop="blur"
        >
            <ModalContent>
                <ModalHeader>
                    Get Early Access
                </ModalHeader>
                <ModalBody>
                    <div style={{ fontWeight: 500, fontSize: 14 }}>First Name</div>
                    <Input
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        label="Enter your first name"
                    />
                    <div style={{ fontWeight: 500, fontSize: 14 }}>Last Name</div>
                    
                    <Input
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        label="Enter your last name"
                    />
                    <div style={{ fontWeight: 500, fontSize: 14 }}>Email</div>
                    <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        label="Enter your email address"
                    />
                    <div style={{ fontWeight: 500, fontSize: 14 }}>How did you find us?</div>
                    <Select
                        value={foundUs}
                        label="How did you find us?"
                        selectedKeys={foundUs}
                        onSelectionChange={setFoundUs}
                    >
                        {options.map((option, index) => (
                            <SelectItem key={option.value} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </Select>

                    {error && (
                        <div style={{ color: "red" }}>
                            All fields are required
                        </div>
                    )}

                    {
                        submitted && (
                            <div>
                                We'll get back to you soon!
                            </div>
                        )
                    }
                </ModalBody>
                <ModalFooter className="flex-col justify-start">
                    <div>
                        {
                            isLinkedin && (
                                <div style={{ fontSize: 12 }}>
                                    You have early access! Click the button below to open the app.
                                </div>
                            )
                        }
                    </div>
                    <Button 
                        style={{
                            borderRadius: 10,
                            color: 'white'
                        }}
                        onPress={onSubmit} 
                        isLoading={loading}
                        size="lg"
                        color={isLinkedin ? "danger" : "primary"}
                    >
                        {isLinkedin ? (<div>🎉Open App</div>) : "Submit"} 
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
