import React from 'react';
import {Button} from '@nextui-org/react';

function PrimaryButton({ children, style, onPress }) {
  return (
    <Button size='lg' onPress={onPress} style={{
        background: "#0000ff",
        color: "white",
        borderRadius: 0,
        marginRight: 10,
        ...style
    }}>{children}</Button>
  );
}

export default PrimaryButton;