import { Button,  Text, Spacer } from '@nextui-org/react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.webp';
import SmallLogo from '../images/main_logo.webp';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import { Navbar } from '@nextui-org/react';
import { StandardButton } from './StandardButton';
import posthog from 'posthog-js'
import PrimaryButton from './Buttons/PrimaryButton';
import { FaDiscord } from 'react-icons/fa';

function Header() {

  const [top, setTop] = useState(true);
  const navigate = useNavigate();
  // detect whether user has scrolled the page down by 10px 
  const [mobile, setHideNav] = useState(window.innerWidth <= 760);
  
  useEffect(() => {
    const resize = () => {
      if(window.innerWidth <= 760) {
        setHideNav(true);
      }
    }
    window.addEventListener("resize", resize);

    return () => window.removeEventListener('resize', resize);
  }, [top]);  

  return (

    <div style={{
      height: 60,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "transparent",
      paddingRight: !mobile ? 10 : 5,
    }}>
      <Link to="/" className="block" aria-label="Cruip">
      </Link>

      <div style={{
        display: "flex"
      }}>

          <Button 
            onPress={() => {
              posthog.capture("Clicked Join Discord", { source: "Header" })
              window.open("https://discord.gg/UKj9DFw3dX", "_blank")
            }}
            variant='ghost'
          style={{
            color: "white",
            borderRadius: 10
          }}>
            <FaDiscord />
            Join our Discord
          </Button>

      </div>
    </div>
    // <Navbar containerCss={{ background: 'rgb(220, 220, 220) !important' }}>
    //   <Navbar.Brand>
        
    //   </Navbar.Brand>

    //   <Navbar.Content>
    //     {/* Make the button link to the /blog page */}

    //   </Navbar.Content>
    // </Navbar>
  );
}

export default Header;
