import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Spacer,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@nextui-org/react";
import Header from "../partials/Header";
import { StandardButton } from "../partials/StandardButton";
import Footer from "../partials/Footer";
import posthog from "posthog-js";

const PricingCard = ({ name, price, features, bottom, color }) => {
  return (
    <Card
      variant="flat"
      style={{
        width: "20em",
        height: 500,
        backgroundColor: "rgb(241, 243, 245)",
        borderRadius: 2,
      }}
    >
      <CardBody>
        <>
          <div justify="center">
            <div
              style={{ 
                fontWeight: "bold",
                fontSize: 20,
              }}
              color="#FFFFFC"
            >
              {name}
            </div>
          </div>
          <Spacer y={1} />
        </>
        

        <>
          <div justify="center">
            <div
              style={{ 
                fontWeight: "bold",
                fontSize: 20,
              }}
              color="#FFFFFC"
            >
              {price}
            </div>
          </div>
          <Spacer y={1} />
        </>


        {features.map((feature, i) => {
          return (
            <div key={i}>
              <div css={{ color: "#FFFFFC" }}>{feature}</div>
              <Spacer y={1} />
            </div>
          );
        })}
        <Spacer y={1} />
      </CardBody>
      <CardFooter css={{ justifyContent: "center" }}>{bottom}</CardFooter>
    </Card>
  );
};

function Pricing({ mobile }) {
  const BottomPart = () => {
    if (mobile) {
      return (
<>
      <PricingCard
          name="Starter Tier"
          price={"Starter"}
          features={["$0.01 per image", "First 1000 images free", "Unlimited projects", "Unlimited users"]}
          color="#0074ff"
          bottom={
            <StandardButton
              onPress={() => {
                window.location.href = "https://app.rubbrband.com";
                posthog.capture("open_app", {
                  'source': 'pricing'
                });
              }}
            >
              Get started for free
            </StandardButton>
          }
        />

        <Spacer x={20} />

        <PricingCard
          name="Enterprise Tier"
          price="Enterprise"
          features={["SSO", "Bulk pricing", "Custom Integrations"]}
          color="black"
          bottom={
            <StandardButton
              onPress={() => {
                window.open("mailto:contact@rubbrband.com", "_blank");

                posthog.capture("clicked_enterprise", {
                  'source': 'pricing'
                });
              }}
              css={{ backgroundColor: "white", color: "black" }}
            >
              Contact us
            </StandardButton>
          }
        />
      </>
      )
    }

    return (
      <div className="flex justify-start w-full" style={{
        padding: 30,
      }}>
        <PricingCard
            name="Starter Tier"
            price={"API Pricing"}
            features={["$0.01 per image", "First 1000 images free", "Unlimited projects", "Unlimited users"]}
            color="#0074ff"
            bottom={
              <StandardButton
                onPress={() => {
                  window.location.href = "https://app.rubbrband.com";
                  posthog.capture("open_app", {
                    'source': 'pricing'
                  });
                }}
              >
                Get started for free
              </StandardButton>
            }
          />

          <Spacer x={5} />

          <PricingCard
            name="Enterprise Tier"
            price="1000"
            features={["SSO", "Volume pricing after 1M/month",  "Unlimited users", "Custom Integrations"]}
            color="black"
            bottom={
              <StandardButton
                onPress={() => {
                  window.open("mailto:contact@rubbrband.com", "_blank");
                }}
                css={{ backgroundColor: "white", color: "black" }}
              >
                Contact us
              </StandardButton>
            }
        />
      </div>
    )
  }
  return (
    <div className="main-container">
      <Header mobile={mobile} />

      <div className="flex flex-col" style={{
        padding: 30,
        paddingLeft: 60, minHeight: '100vh',
      }}>

        <div weight="bold" style={{
          fontSize: 40,
        }}>
            Pricing
        </div>

        <Spacer y={2} />

        <div>We charge based on the number of requests you send us, billed monthly.</div>

        <Spacer y={2} />

        <div>If you have questions about pricing, <a style={{ textDecoration: "underline" }} href="mailto:contact@rubbrband.com">contact us</a></div>

        <Spacer y={2} />

        <Table aria-label="Example static collection table">
          <TableHeader>
            <TableColumn>Number of requests</TableColumn>
            <TableColumn>Price</TableColumn>
          </TableHeader>
          <TableBody>
            {/* $700 max */}
            <TableRow key="1">
              <TableCell>First 100K requests</TableCell>
              <TableCell>$0.007</TableCell>
            </TableRow>

            {/* $630 max */}
            <TableRow key="2">
              <TableCell>Next 100K - 1M requests</TableCell>
              <TableCell>$0.0007</TableCell>
            </TableRow>

            <TableRow key="3">
              <TableCell>More than 1M requests</TableCell>
              <TableCell><Button onPress={() =>{
                window.open("mailto:contact@rubbrband.com", "_blank");
              }} color="primary" style={{ color: "white" }}>Contact us for volume pricing</Button></TableCell>
            </TableRow>
          </TableBody>
        </Table>


      </div>

      <Spacer y={2} />

      <Footer mobile={mobile} />

    </div>
  );
}

export default Pricing;