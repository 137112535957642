import React, { useEffect } from 'react';
import { Image, Spacer, Button, Chip, Input } from '@nextui-org/react';
import './Header.css';
import MainLogo from '../images/logo_white.webp';
import BlackLogo from '../images/Black.png';
import axios from 'axios';
import WaitlistModal from './WaitlistModal';

function HeroHome({ mobile }) {
  const [visible, setVisible] = React.useState(false);

  return (
    <div className="flex">
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}>
        <WaitlistModal 
          visible={visible}
          setVisible={setVisible}
        />
        <div>
          <img
            src={MainLogo}
            onClick={() => {
              window.open("mailto:contact@rubbrband.com", "_blank")
            }}
            style={{
              width: 200,
              marginBottom: 30,
              cursor: "pointer"
            }}
          />
        </div>

        <div className="text-3xl text-white" style={{
          textAlign: "center",
          fontWeight: 400,
        }}>
          The Next-Gen Creativity Tool
        </div>

        <Spacer y={10} />

        <Button
          onPress={() => setVisible(true)}
          style={{
            backgroundColor: "#77c4ff",
            borderRadius: 10
          }}
        >
          Get Early Access
        </Button>

        <Spacer y={5} />

        <img
          src={BlackLogo}
          style={{
            width: 120,
            marginTop: 30
        }} />
      </div>
    </div>
  );
}


export default HeroHome;