import Markdown from "markdown-to-jsx"
import React, { useEffect, useState } from 'react';
import {  User,   Spacer,  } from '@nextui-org/react';
import Header from '../partials/Header';
import { useParams } from 'react-router';

import './blog.css';

const BlogPost = ({ mobile }) => {
    const { filename } = useParams();

  const [postContent, setPostcontent] = useState('');
  useEffect(() => {
    import(`../posts/${filename}.md`)
      .then(res =>
        fetch(res.default)
          .then(response => response.text())
          .then(response => {
            setPostcontent(response)
        })
          .catch(err => console.log(err))
      )
  }, []);
  
  if(mobile) {
    return (
        <div
        style={{ 
            backgroundColor: 'white'
         }}
        >
            <Header />

            <Spacer y={1} />
            <Markdown>
                {postContent}
            </Markdown>

            <Spacer y={1} />
            <User 
                src="https://i.ibb.co/3S6nzZw/Screen-Shot-2022-12-06-at-11-48-24-AM.webp"
                name="Jeremy Lee"
                css={{ 
                    color: "black",
                    "span": {
                        color: 'black'
                    }
                    }}
            >
                <User.Link href="https://twitter.com/jrmyjlee/">@jrmyjlee</User.Link>
            </User>
            <Spacer y={1} />
        </div>

    )
  }
  return (
    <div className="flex flex-col min-h-screen overflow-hidden"
        style={{ 
            backgroundColor: 'white'
         }}
        >
            <Header />
            <div gap={mobile ? 12 : 5} justify='center'>
                <div span={6} css={{ 
                    background: 'white',
                    padding: 20,
                    borderRadius: 3
                }}>
                    <Markdown
                        options={{
                            overrides: {
                            //   code: {
                            //     component: Code,
                            //     props: {
                            //       isDark: true,
                            //       setIsDark: true
                            //     }
                            //   },
                            //   h2: {
                            //     component: () => {}
                            //   }
                            }
                          }}
                    >
                        {postContent}
                    </Markdown>
                </div>
                <div 
                    span={1.5}
                    css={{
                        background: 'white',
                        padding: 20,
                        borderRadius: 3
                    }}>
                    <div weight="medium" size={20} color='black'>Written by</div>
                    <User 
                        src="https://i.ibb.co/3S6nzZw/Screen-Shot-2022-12-06-at-11-48-24-AM.webp"
                        name="Jeremy Lee"
                        css={{ 
                            color: "black",
                            "span": {
                                color: 'black'
                            }
                         }}
                    >
                        <User.Link href="https://twitter.com/jrmyjlee/">@jrmyjlee</User.Link>
                    </User>
                </div>
            </div>
        </div>
    );
}

export default BlogPost;
