import React from 'react';
import {  Button, Image, Spacer } from '@nextui-org/react';
import { Link } from 'react-router-dom';
import logo from '../images/logo_white.webp';
import { StandardButton } from './StandardButton';
import posthog from 'posthog-js';
import PrimaryButton from './Buttons/PrimaryButton';

function Footer({ mobile }) {
  const TopPart = () => {
    if (mobile) {
      return (
        <>
        <div>
          <div className="text-white" size={60} weight="bold">
          Get started with Rubbrband
          </div>
        </div>

        <Spacer y={4} />

        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <Button 
            color="primary"
            onPress={() => {
              window.location.href = "https://app.rubbrband.com";
              posthog.capture("open_app", {
                'source': 'footer'
              });
            }}
          >Get started</Button>
        </div>
        </>
      )
    }

    return (
      <div justify='center' align='center'>        <div>
        <div className="text-white text-4xl" weight="medium">
            Get started with Rubbrband
          </div>
        </div>

        <Spacer y={4} />

        <Image src="https://pbxt.replicate.delivery/NOB76By6TPrSONHiWcuZMUl7i9AsHa0HN5RNMv7brLeqQhyIA/out-0.png" width={600} height={600} />

        <Spacer y={20} />

        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <PrimaryButton color="primary" 
            style={{
              color: "white"
            }}
            onPress={() => {
              window.location.href = "https://app.rubbrband.com";
              posthog.capture("open_app", {
                'source': 'footer'
              });
            }}>Get started</PrimaryButton>
        </div>
      </div>
    )
  }
  return (
    <div style={{ backgroundColor: "black", width: '100vw', m: 0, padding: 50 }}>
      <TopPart />
      <div className="flex justify-between pt-20 flex-wrap">
        <div style={{ width: mobile && '100%', marginBottom: mobile && 30 }}>
          <Link to="/">
            <div css={{ pl: 2, mb: 5 }}>
              <img src={logo} width={125} />
            </div>
          </Link>

          <div style={{ display: "flex" }}>
            <div className="text-neutral-400" size={15}>A Y Combinator company</div>
          </div>
        </div>

        <div style={{ marginBottom: mobile && 30 }}>
          <div size={15} weight="bold" className="text-neutral-400">Contact</div>
          <div><a style={{ color: "white", fontWeight: "medium", fontSize: 15 }} href="mailto:contact@rubbrband.com">Email</a></div>
          <div><a style={{ color: "white", fontWeight: "medium", fontSize: 15 }} href="https://discord.gg/BW3R9yK7Fh">Discord</a></div>
        </div>

        <div>
          <div size={15} weight="bold" className="text-neutral-400">Documentation</div>
          <div><a style={{ color: "white", fontWeight: "medium", fontSize: 15 }} href="https://app.rubbrband.com/docs/intro">Intro</a></div>
          <div><a style={{ color: "white", fontWeight: "medium", fontSize: 15 }} href="https://app.rubbrband.com/docs/replicate">Integrations</a></div>
        </div>


      </div>
    </div>
  );
}

export default Footer;