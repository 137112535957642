import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import {Helmet} from "react-helmet";

import './css/style.scss';

import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Waitlist from './pages/Waitlist';
import About from './pages/About';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import posthog from 'posthog-js'

if (process.env.REACT_APP_ENVIRONMENT == "prod") {
  posthog.init('phc_WyQdajoejtKCJKgxdsMpvQAnK4D170GVYM5ocKpKKtz', { api_host: 'https://app.posthog.com' })
}
  
function App() {

  const location = useLocation();
  const [mobile, setHideNav] = useState(window.innerWidth <= 760);
  // const [darkMode, setDarkMode] = useState(true);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rubbrband - Next-gen Creative Suite</title>
        <meta name="description" content="Rubbrband - Next-gen Creative Suite">
        </meta>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<Home mobile={mobile}/>} />
        <Route exact path="/pricing" element={<Pricing mobile={mobile}/>} />
        <Route exact path="/about" element={<About mobile={mobile}/>} />
        <Route exact path="/blog" element={<Blog mobile={mobile}/>} />

        <Route exact path="/blog/:filename" element={
          <BlogPost 
            // setDarkMode={setDarkMode} 
            mobile={mobile}/>} 
          />
      </Routes>
    </>
  );
}

export default App;
