import React, { useEffect, useState } from 'react';
import {   Card,   Spacer } from '@nextui-org/react';
import Header from '../partials/Header';
import { Link } from 'react-router-dom';
function Blog({ mobile }) {
    const [posts, setPosts] = useState([
        {
            id: 3,
            "title": "Training a Stable Diffusion for Music (Part 1)",
            "description": "Preprocessing audio data for training a ML model",
            "author": "Jeremy Lee",
            "date": new Date("2022/12/14"),
            "filename": "preprocess-music"
        },
        {
            id: 1,
            "title": "Creating Python Environments for Machine Learning Projects",
            "description": "This is a description of the post",
            "author": "Jeremy Lee",
            "date": new Date("2022/06/12"),
            "image": "https://i.ibb.co/8jyWnx8/DALL-E-2022-12-06-15-04-39-a-photo-of-three-different-multiverses-that-look-like-portals-each-with-i.webp",
            "filename": "envs"
        },
        {
            id: 2,
            "title": "Why we're building Rubbrband",
            "description": "This is a description of the post",
            "author": "Jeremy Lee",
            "date": new Date("2022/10/01"),
            "image": "https://i.ibb.co/kH1qsyc/DALL-E-2022-12-06-14-37-01-a-rubberband-flying-through-space-digital-art.webp",
            "filename": "why"
        },
    ]);
    
    useEffect(() => {
    }, [])
    

    return (
    <div>
        <Header />
        <div weight="bold" size={"$5xl"}>Blog</div>
        <Spacer y={1} />
        <div>
            {posts.map(post => (
                    <div align='center' key={post.id} css={{ 
                        minWidth: 400,
                        maxWidth: 600,
                        height: 200,
                        borderBottom: "1px solid #e5e5e5",
                    }}>
                        {
                            !mobile ? (
                                <div span={4}>
                                    <img height={150} width={150} src={post.image} style={{ borderRadius: 7 }} />
                                </div>
                            ) : (
                                <img height={50} width={50} src={post.image} style={{ borderRadius: 7 }} />
                            )
                        }
                        <Spacer x={.5} />

                        <div>
                            <Link to={`/blog/${post.filename}`}>
                                <div weight="bold" size={20}>{post.title}</div>
                            </Link>
                            <div>
                                <div css={{ color: "#bdffd0" }} weight="medium" size={16}>{post.author}</div>
                                <Spacer x={0.5} />
                                <div size={16}>{post.date.toDateString()}</div>
                            </div>
                        </div>
                    </div>
            ))}
        </div>
    </div>
    );
}

export default Blog;