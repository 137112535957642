import React, { useEffect, useState } from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';

import Footer from '../partials/Footer';
import Evals from '../partials/Evals/Evals';
import Features from '../partials/Evals/Features';
import Scale from '../partials/Evals/Scale';
import Monitor from '../partials/Evals/Monitor';

const code = `import rubbrband
from diffusers import StableDiffusionPipeline

prompt = "a photograph of an astronaut riding a horse"
pipe = StableDiffusionPipeline.from_pretrained("CompVis/stable-diffusion-v1-4")
image = pipe(prompt).images[0]

rubbrband.upload(image, prompt)
`

function Home({ mobile }) {

  const Break = () => {
    return (
      <div style={{ height: 50, marginTop: 50 }} />
    )
  }
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);
  return (
    <div className="main-container"
    >
      <Header mobile={mobile} />

      <HeroHome mobile={mobile}/>

      {/* <PromptEngineering mobile={mobile}/> */}
      </div>
  );
}

export default Home;