import React from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import {
  
  
  
  
  Card,
  Button,
  Spacer,
} from "@nextui-org/react";

function About() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
    <Header />
    <div className="max-w-6xl mx-auto px-4 sm:px-6" style={{marginTop: 100}}>
    <div css={{ height: '70vh' }}>
      <div size={25} weight="bold" color="black">
        About Us
      </div>
      <Spacer y={1} />
        <Card css={{ justifyContent: 'center', alignItems: 'center', width: 700, height: 300 }}>
            <Card.Header>
            <div>
                <div weight={"bold"}>Per-Usage Plan</div>
                <div color={"#27262a91"}>You are charged for the number of repos you use per month.</div>
            </div>
            </Card.Header>
            <Card.Body>
            <div size={12} color="#27262a91">
                <div weight={"bold"} size={25}>$25</div>
                per repo</div>
            <Spacer y={1} />
            <Button onClick={() =>{
            window.location.href = "https://app.rubbrband.com";
            return null;
            }} css={{ position: 'absolute', bottom: 20 }}>Get started with your first repo</Button>
            </Card.Body>
        </Card>

      <Spacer y={2} />
    </div>
    </div>
    <Footer />
    </div>
  );
}

export default About;